/* ---------------------------------------- GERAL ---------------------------------------- */

.form.main {
  padding: 60px;
  max-width: 800px;
  margin: auto;
  border-radius: 10px;
  border: solid 1px #bfbfbf;
  background-color: #fcfcfc;
  gap: 50px;
  grid-gap: 50px;
  display: flex;
  flex-direction: column;
}

.formulario-de-reservas {
  border-top: 50px solid #abc132;
  padding: 40px 10px;
}

.linha-principal {
  height: 1.5px !important;
  background-color: #5b5a5d !important;
}

.campo-com-erro .form.main h1 {
  font-size: 32px;
  font-weight: bold;
  color: #5b5a5d;
  margin: 0px;
}

.resumo-pedido-page .linha-principal {
  width: 100%;
  height: 1.5px !important;
  background-color: #5b5a5d !important;
}

.sub-linha {
  height: 1px;
  background-color: #bfbfbf;
  width: 100%;
}

.label-do-campo {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #5b5a5d;
}

.all-checkbox {
  margin-right: 10px !important;
}

.all-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #707070;
  border-radius: 4px;
  outline: none;
  position: relative;
  top: 2px;
  transition: border-color 0.3s;
  margin: 0px;
  transition: .3s;
}

.all-checkbox:checked {
  border-color: #707070;
  background-color: #abc132;
}

.all-checkbox:checked:after {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}



.flex-col-titulo {
  display: flex;
  align-items: flex-end;
  gap: 7px;
  grid-gap: 7px;
}

.label-info {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: 0.26px;
  text-align: left;
  color: #5b5a5d;
}

.icons-web {
  margin-right: 10px;
  fill: #bfbfbf;
}

input,
textarea,
select {
  outline-color: #ABC032;
}

input::placeholder,
textarea::placeholder {
  color: #bfbfbf !important;
}



.flex-col-titulo span {
  color: #5b5a5d;
}

.resumo-pedido-main-sec-m {
  display: none !important;
}

/* ---------------------------------------- END - GERAL ---------------------------------------- */






/* ---------------------------------------- FORMULARIO ---------------------------------------- */

.sec-formulario {
  display: flex;
  flex-direction: column;
  gap: 40px;
  grid-gap: 40px;
}

.submeterreserva {
  border-radius: 5px;
  border: solid 1px #bfbfbf;
  width: 176px;
  height: 56px;
  background-color: #bfbfbf;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5b5a5d;
  font-weight: 600;
  cursor: pointer;
}

.submeterreserva:hover {
  background-color: #abc132;
}



/* ---------------------------------------- END - FORMULARIO ---------------------------------------- */



/* ---------------------------------------- SELECT ---------------------------------------- */

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url('/src/assets/imagens/arrow-selector.png');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 9px;
}


.custom-select select option {
  height: 40px;
  overflow-y: scroll;
  background-color: white;
  color: #333;

}

.custom-select select option:hover {
  background-color: #f5f5f5;
  color: black;
}




/* ---------------------------------------- END - SELECT ---------------------------------------- */






/* ---------------------------------------- ENTRADA E SAIDA ---------------------------------------- */


.horasindisponiveisentrega .ant-select-selector {
  pointer-events: none;
}

.horasindisponiveisrecolha .ant-select-selector {
  pointer-events: none;
}



.sec-entrada-recolha {
  display: flex;
  /* justify-content: space-between; */
  gap: 40px;
  grid-gap: 40px;
  /* margin-top: 40px; */
}

.sec-entrada {
  width: 50%;
  z-index: 1;
}

.sec-recolha {
  width: 50%;
  z-index: 1;
}

.input-calendario {
  height: 48px;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  border: solid 1px #bfbfbf;
  padding: 0px 10px;
  font-size: 18px;
  box-sizing: border-box;
}

.col-sec-entrada-recolha {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-gap: 10px;
  margin-top: 15px;
}

.calendariosaida-open-col .input-calendario {
  border: 1px solid #ABC032;
  outline: #ABC032 auto 1px;
}

.calendariosaida-saida-col .input-calendario {
  border: 1px solid #ABC032;
  outline: #ABC032 auto 1px;
}


.selecao-da-hora-entrada,
.selecao-da-minuto-entrada {
  padding: 12px 16px;
  border-radius: 9px;
  border: solid 1px #bfbfbf;
  background-color: #fff;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.4px;
  color: #bfbfbf;
  width: 72px;
  height: 48px;
}

.selecao-da-hora-entrada:hover,
.selecao-da-hora-entrada:focus {
  border-color: #aaa;
  color: #000;
}

.selecao-da-minuto-entrada:hover,
.selecao-da-minuto-entrada:focus {
  border-color: #aaa;
  color: #000;
}

.selecao-da-hora-entrada option,
.selecao-da-minuto-entrada option {
  padding: 5px;
}

.col-horas-de-entrada {
  display: flex;
  flex-direction: row;
  gap: 10px;
  grid-gap: 10px;
  align-items: center;
  margin-top: 8px;
}


.info-valet-service {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: 0.26px;
  text-align: left;
  font-weight: 900;
  color: #abc132;
}

.info-valet-service-label {
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: 0.26px;
  text-align: left;
  font-weight: 500;
  color: #5b5a5d;
}

/* .num-pessoas-entrada-recolha {
  margin-top: 10px;
} */

.selecao-das-pessoas {
  padding: 12px 16px;
  border-radius: 9px;
  border: solid 1px #bfbfbf;
  background-color: #fff;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.4px;
  color: #bfbfbf;
  width: 72px;
  height: 48px;
}

.selecao-das-pessoas:hover,
.selecao-das-pessoas:focus {
  border-color: #aaa;
  color: #000;
}

.selecao-das-pessoas option,
.selecao-das-pessoas option {
  padding: 5px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  font-size: 18px;
}


/* ---------------------------------------- END - ENTRADA E SAIDA ---------------------------------------- */


/* ---------------------------------------- ESPAÇO ---------------------------------------- */

.flex-col-espaco {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 48px;
  grid-gap: 48px;
  margin-top: 15px;
}

.flex-col-espaco span {
  font-size: 18px;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.4px;
  font-weight: bold;
  font-style: italic;
  color: #abc132;
}

.flex-col {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  grid-gap: 8px;
}

/* ---------------------------------------- END - ESPAÇO ---------------------------------------- */





/* ---------------------------------------- DADOS PESSOAIS ---------------------------------------- */

.col-dados-pessoais {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 20px;
  grid-gap: 20px;
}

.col-dados-pessoais-rapida {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  gap: 20px;
  grid-gap: 20px;
}

.subcol-dados-pessoais input {
  width: 170px;
  color: #000;
  font-size: 18px;
  padding: 0px 10px;
  border: solid 1px #bfbfbf;
}

.icon-question {
  font-size: 20px;
  position: relative;
  top: 5px;
  left: 5px;
  color: #abc132;
}

.input-calendario::placeholder {
  color: #bfbfbf;
}

.col-email-dados-pessoais {
  width: 418px;
}

.col-email-dados-pessoais input {
  width: 100%;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 47px;
  color: #000 !important;
}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #ABC032;
  outline-color: #ABC032 !important;
}

/* ---------------------------------------- END - DADOS PESSOAIS ---------------------------------------- */






/* ---------------------------------------- DETALHES DO VOO ---------------------------------------- */

.col-detalhes-do-voo {
  display: flex;
  margin-bottom: 15px;
  gap: 48px;
  grid-gap: 48px;
}

.subcol-detalhes-voo input {
  width: 90px;
  color: #000;
  font-size: 16px;
  padding: 0px 10px;
  border: solid 1px #bfbfbf;
  text-transform: uppercase;
}

.input-detalhe-voo {
  height: 48px;
  border: solid 1px #707070;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
}

.input-calendario::placeholder {
  color: #bfbfbf;
}

.col-email-dados-pessoais {
  width: 418px;
}

.col-email-dados-pessoais input {
  width: 100%;
}

.col-detalhes-do-voo img {
  width: 40px;
  margin-right: 5px;
}

.col-detalhes-do-voo .flex-col {
  margin-top: 7px;
}


/* ---------------------------------------- END - DDETALHES DO VOO ---------------------------------------- */



/* ---------------------------------------- DADOS RESERVA ---------------------------------------- */

.col-dados-reserva {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 20px;
  grid-gap: 20px;
}

.input-dados-reserva {
  height: 48px;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  border: solid 1px #bfbfbf;
  padding: 0px 10px;
  font-size: 18px;
  box-sizing: border-box;
}

.subcol-dados-reserva input {
  width: 170px;
  color: #000;
  font-size: 18px;
  padding: 0px 10px;
  border: solid 1px #bfbfbf;
}

.input-matricula {
  text-transform: uppercase;
  /* width: 115px !important; */
}

.input-matricula::placeholder {
  color: #bfbfbf;
}

.selecao-da-marca-carro {
  padding: 12px 16px;
  border-radius: 9px;
  border: solid 1px #bfbfbf;
  background-color: #fff;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.4px;
  width: 200px;
  height: 48px;
}

.selecao-da-marca-carro option:first {
  color: #bfbfbf !important;
}

select:invalid {
  color: gray;
}

.selecao-da-marca-carro:hover,
.selecao-da-marca-carro:focus {
  border-color: #aaa;
  color: #000;
}

.selecao-da-marca-carro option {
  padding: 5px;
}



/* ---------------------------------------- END - DADOS RESERVA ---------------------------------------- */



/* ---------------------------------------- EXTRAS MONOVOLUMES E PICK-UPS ---------------------------------------- */

.col-extraxmonopicks {
  display: flex;
  justify-content: space-between;
}

.col-extraxmonopicks span {
  font-size: 18px;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.4px;
  font-weight: bold;
  font-style: italic;
  color: #abc132;
}

/* ---------------------------------------- END - EXTRAS MONOVOLUMES E PICK-UPS ---------------------------------------- */


/* ---------------------------------------- EXTRAS ---------------------------------------- */

.col-extras {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 16px;
}

.col-extras .label-do-campo {
  font-size: 16px;
}

.col-extras span {
  font-size: 16px;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.4px;
  font-weight: bold;
  font-style: italic;
  color: #abc132;
}

/* ---------------------------------------- END - EXTRAS ---------------------------------------- */


/* ---------------------------------------- MENSAGEM / OBSERVAÇÕES ---------------------------------------- */

textarea {
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  border-radius: 9px;
  border: solid 1px #bfbfbf;
  background-color: #fff;
  line-height: 1.6;
  text-align: left;
  color: #000;
  padding: 10px 20px;
  box-sizing: border-box;
  resize: none;
}

.textarea::placeholder {
  padding: 20px;
}

.sec-formulario .terms {
  color: #abc132;
  text-decoration: underline;
}

.aceiteostermos {
  margin-top: 40px;
}

/* ---------------------------------------- END - MENSAGEM / OBSERVAÇÕES ---------------------------------------- */











.rad-label {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  cursor: pointer;
  transition: .3s;
  gap: 10px;
  grid-gap: 10px;
}

.rad-input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: 1;
}

.rad-design {
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background: #abc132;
  position: relative;
  border: solid 1px #707070;
}

.rad-design::before {
  content: '';

  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  background: #fff;
  transform: scale(1.1);
  transition: .3s;
}

.rad-input:checked+.rad-design::before {
  transform: scale(0);
}



.svg-important {
  fill: #ABC032 !important;
  /* Altere a cor conforme desejado */
}







/* ---------------------------------------- CALENDARIO ---------------------------------------- */

.calendario {
  display: flex;
  /* width: 600px; */
  position: absolute;
  top: 148px;
  z-index: 1;
}

.overlay-bloco-calendario {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.overlay-bloco-calendario-close {
  height: 0px;
  width: 0px;
  margin-bottom: -40px;
}

.sec-calendario {
  height: 390px;
  border-radius: 9px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 2px #abc132;
  background-color: #fff;
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  grid-gap: 20px;
  z-index: 1;
}

@media only screen and (min-width: 1025px) {
  .sec-calendario {
    width: 350px;
    height: 390px;
    border-radius: 9px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 2px #abc132;
    background-color: #fff;
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    grid-gap: 20px;
    z-index: 1;
  }
}

.dia-destaque-calendario {
  width: 250px;
  height: 390px;
  border-radius: 0px 9px 9px 0px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 2px #abc132;
  background-color: #abc132;
  color: #fff;
  padding: 15px;
  margin-left: -10px;
  text-align: center;
}

.select-dos-meses {
  padding: 12px 25px;
  border-radius: 9px;
  border: solid 1px #bfbfbf;
  background-color: #fff;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.4px;
  color: #bfbfbf;
  width: 140px;
  height: 48px;
}

.select-dos-meses:hover,
.select-dos-meses:focus {
  border-color: #aaa;
  color: #000;
}


.select-dos-meses option {
  padding: 5px;
}

.col-mes-ano-calendario {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  grid-gap: 10px;
  width: 100%;
}

.col-mes-ano-calendario .ant-select-single {
  width: 100%;
}

.calendario-main-sec {
  display: table;
  width: 100%;
  text-align: center;
}

.calendario-main-sec .dias-da-semana {
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: #5b5a5d;
}

.calendario-main-sec .todos-dias-do-mes {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: #5b5a5d;
}

.calendario-main-sec .todos-dias-do-mes .dia-disponivel td:hover {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: #5b5a5d;
  cursor: pointer !important;
}

.calendario-main-sec .dia-calendario.dia-livre:hover {
  border-radius: 10px;
  background-color: #abc132 !important;
  /* color: #fff; */
  cursor: pointer;
}

.calendario-main-sec .dia-indisponivel.dia-calendario.dia-livre:hover {
  color: #bfbfbf;
  background-color: #fff !important;
}

.calendario-main-sec .dia-poucas_vagas:hover {
  border-radius: 10px;
  background-color: #ffbb00 !important;
  color: #fff;
  cursor: pointer;
}

.dia-indisponivel {
  color: #bfbfbf;
}

.dia-indisponivel.dia-selecionado,
.dia-indisponivel.dia-selecionado-saida {
  color: #bfbfbf;
  background-color: #fff !important;
}

.calendario-main-sec table {
  width: 100%;

}

.dia-destaque-calendario h3 {
  font-size: 21px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 2.88px;
  text-align: center;
  color: #fff;
}

.dia-destaque-calendario .custo-do-dia {
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #5b5a5d;
  padding: 15px 0px;
}

.destaque-diaselecionado {
  font-size: 96px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.dia-calendario.dia-livre.dia-selecionado {
  border-radius: 10px;
  background-color: #abc132;
  color: #5b5a5d;
}


.dia-calendario.dia-poucas_vagas {
  background: #fff;
  color: #ffbb00;
}

.dia-poucas_vagas.dia-selecionado-saida {
  background: #ffbb00;
  color: #fff;
}

.dia-disponivel.dia-calendario.dia-poucas_vagas.dia-entre-selecionado {
  background: #ffbb00;
  color: #fff;
}


.dia-poucas_vagas {
  border-radius: 10px;
  background-color: #ffbb00;
  color: #fff;
  cursor: pointer;
}



.dias-no-calendario-main .preco-individual {
  font-size: 10px;
  height: 15px;
  color: #5b5a5d;
}

.dias-no-calendario-main .dia-calendario {
  display: flex;
  flex-direction: column;
  height: 41px;
}

.dia-individual {
  height: 28px;
}

.custo-do-dia {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #5b5a5d;
}

.custo-do-dia-price {
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.dia-indisponivel {
  text-decoration: line-through;
  color: #bfbfbf;
  background-color: #fff !important;
  cursor: not-allowed !important;
}


.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: transparent !important;
}

.dias-no-calendario-main .dia-indisponivel .dia-teste:hover {
  color: #bfbfbf !important;
  font-weight: normal !important;
}

.dia-indisponivel .dia-selecionado-hover {
  color: #fff;
}

.dia-livre.dia-selecionado-hover {
  border-radius: 10px;
  background-color: #bfdc1a !important;
  color: #5b5a5d;
  cursor: pointer;
}

.dia-calendario.dia-poucas_vagas.dia-selecionado {
  background-color: #ffbb00;
  color: #fff;

}

.dia-disponivel.dia-calendario.dia-entre-selecionado {
  border-radius: 10px;
  background-color: #bfdc1a;
  color: #5b5a5d;
  cursor: pointer;
}

.dia-disponivel.dia-calendario.dia-poucas_vagas.dia-selecionado-hover {
  border-radius: 10px;
  background-color: #ffbb00;
  color: #fff;
  cursor: pointer;
}

.dia-indisponivel.dia-calendario.dia-poucas_vagas {
  color: #bfbfbf;
  background-color: #fff !important;
}

/* .dia-disponivel .dia-calendario.dia-poucas_vagas.dia-selecionado-hover:hover{
  background-color: #ffbb00 !important;
} */

.calendario-main-sec .dia-poucas_vagas.dia-selecionado-hover:hover {
  background-color: #ffbb00 !important;
}

/* .dia-indisponivel.dia-selecionado {
    background-color: #fd595e !important;
    color: #fff !important;
  }
  
  .dia-indisponivel.dia-selecionado.dia-selecionado-hover {
    background-color: #fd595e !important;
    color: #fff !important;
  }
  
  .dia-indisponivel.dia-selecionado.dia-selecionado-hover:hover {
    color: #fff !important;
  } */

.dia-selecionado-saida {
  border-radius: 10px;
  background-color: #abc132;
  color: #5b5a5d;
  cursor: pointer;
}

.btn-close-calendario {
  position: absolute;
  right: 20px;
}

.btn-close-calendario img {
  width: 18px;
  cursor: pointer;
}


.calendarioentrada-open {
  left: 0px;
  transition: left 500ms;
}

.calendarioentrada-saida {
  left: 142px;
  transition: left 500ms;
}

.bloco-calendario-main-col.openResumodoPedido-aberto{
  top: 739px;
}

.bloco-calendario-main-col {
  width: 100%;
  position: absolute;
  top: 539px;
}

.arrow-anteriorcalendario,
.arrow-proximocalendario {
  align-self: center !important;
}

.arrow-proximocalendario img {
  width: 17px;
  cursor: pointer;
}

.arrow-anteriorcalendario img {
  width: 17px;
  cursor: pointer;
}

/* ---------------------------------------- END - CALENDARIO ---------------------------------------- */



/* ---------------------------------------- RESUMO DO PEDIDO ---------------------------------------- */


/* .ticket-resumodopedido {
    position: fixed;
    bottom: 0px;
    right: 18px;
    background: #5b5a5d;
    padding: 20px;
    border-radius: 9px 9px 0px 0px;
    width: 200px;
    color: #fff;
    z-index: 2;
  } */
/* .ticket-resumodopedido {
  
  } */

/* .ticket-resumodopedido.open {
    padding: 30px 60px;
    max-width: 800px;
    position: fixed;
    bottom: 20px;
    margin: auto;
    background: #5b5a5d;
    border-radius: 9px;
    color: #fff;
    z-index: 9999;
    left: 0px;
    right: 0px;
  } */

.infos-resumodopedido {
  padding: 30px 60px;
  max-width: 800px;
  position: sticky;
  background: #5b5a5d;
  border-radius: 9px;
  color: #fff;
  z-index: 9999 !important;
  left: 0px;
  display: flex;
  right: 0px;
  justify-content: space-between;
  margin: auto;
  gap: 10px;

}

@media only screen and (min-width: 741px) {
  .resumo-pedido-main-sec {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 15%, rgba(251, 252, 252, 0) 50%);
    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;
  }
}

.ticket-resumodopedido {
  position: sticky;
  top: 0px;
  z-index: 9999;
}

.ticket-resumodopedido.infos-resumodopedido h3 {
  margin: 0pxs;
}

.infos-resumodopedido-col3 {
  display: flex;
  flex-direction: column;
}

.infos-resumodopedido-col4 {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ticket-resumodopedido.open .infos-resumodopedido {
  opacity: 1;
  transition: opacity 1000ms;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.totaldareserva-marcacao h2 {
  color: #fff;
}

.precototaldareserva {
  color: #ffffff;
  font-size: 26px;
  font-weight: 600;
  background: #abc132;
  text-align: center;
  width: 100%;
  border-radius: 9px;
  margin-bottom: 10px;
}

.precototaldareserva-marcacaorapida {
  color: #5b5a5d;
  font-size: 26px;
  font-weight: 600;
  background: #fcfcfc;
  text-align: center;
  width: 100px !important;
  border-radius: 9px;
  display: flex;
  height: 34px;
  justify-content: center;
  align-items: center;
}

.precototaldareserva-info {
  font-size: 10px;
  margin: 0px;
}

.frase-resumodopedido {
  color: #abc132 !important;
}

.arrows-ticket {
  text-align: center;
  margin-top: 10px;
}

.down-ticket img {
  transform: rotate(270deg) !important;
  filter: brightness(2.5);
  transition: transform 500ms;
  width: 18px;
  cursor: pointer;
}

.up-ticket img {
  transform: rotate(90deg) !important;
  filter: brightness(2.5);
  transition: transform 500ms;
  width: 18px;
  cursor: pointer;
}

.resumodopedio-bem-vindo {
  padding: 5px;
}

.resumodopedio-bem-vindo h2 {
  font-size: 24px;
  text-align: center;
}

.resumodopedio-bem-vindo p {
  text-align: center;
  margin-bottom: 0px;
  font-size: 18px;
}


/* ---------------------------------------- END - RESUMO DO PEDIDO ---------------------------------------- */



/* ---------------------------------------- RESUMO DA MARCAÇÃO ---------------------------------------- */

.sec-formulario-registos {
  display: flex;
  flex-direction: column;
  /* margin-top: 40px; */
  gap: 50px;
  grid-gap: 50px;
}


.flex-resumo-page {
  display: flex;
  flex-direction: row;
  gap: 30px;
  grid-gap: 30px;
}

.flex-resumo-page-col {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.sec-estadopagamento-serextrares span {
  line-height: 0px !important;
}

.flex-resumo-page-col span {
  line-height: 25px;
}

.reserva-paga {
  color: #ABC132;
  text-decoration: underline;
}

.reserva-nao-paga {
  color: #ff595e;
  text-decoration: underline;
}

.reserva-aguarda-paga {
  color: #FFCC00;
  text-decoration: underline;
}

.sec-formulario-registos ul {
  padding-left: 20px;
  margin: 0px;
}

.sec-formulario-registos li::marker {
  content: '✓ ';
  font-size: 1.2em;
}

/* ---------------------------------------- END - RESUMO DA MARCAÇÃO ---------------------------------------- */

/* .error-message-section {
    width: 600px;
    left: 0px;
    top: 407px;
    border: solid 2px #ff595e;
    background-color: #fff;
    display: flex; 
    position: absolute;
    border-radius: 9px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  } */

/* .pulse:hover {
    animation: none;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }
  
    50% {
      transform: scale(1);
    }
  
    100% {
      transform: scale(0.9);
    }
  }
  
  .pulse {
    animation: pulse 4s infinite;
  } */

/* .error-message-col {
    padding: 6px;
  } */

/* .btn-close-mensagem-erro {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  
  .btn-close-mensagem-erro img {
    width: 14px;
    cursor: pointer;
  } */

/* .infos-resumodopedido {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

/* .infos-resumodopedido .frase-resumodopedido {
    padding-top: 30px;
  } */

.infos-resumodopedido h2 {
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding-bottom: 10px;
}

.infos-resumodopedido h3 {
  text-transform: uppercase;
  margin: 0px;
}



/* ---------------------------------------- ERROS INPUT CALENDARIO ---------------------------------------- */


.campo-com-erro {
  border: 1px solid #fd595e !important;
}

.selecthorario.campo-com-erro {
  border: none !important;
}

.selecthorario.campo-com-erro .ant-select-selector {
  border: 1px solid #fd595e !important;
}

.campo-com-erro-tel .ant-select-selector {
  border: 1px solid #fd595e !important;
}

.pronto-a-enviar-form,
.pronto-a-enviar-form:hover {
  background: #ABC032 !important;
}

.form-nao-esta-pronto,
.form-nao-esta-pronto:hover {
  background: #BFBFBF !important;
}

.form-nao-esta-pronto {
  cursor: not-allowed;
  pointer-events: none !important;
}

/* .campo-com-content{
  border: solid 1px #bfbfbf;} */