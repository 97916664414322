/* @media (min-width: 640px) { ... }
@media (min-width: 768px) { ... }
@media (min-width: 1024px) { ... }
@media (min-width: 1280px) { ... }
@media (min-width: 1536px) { ... } */





@media only screen and (min-width: 1025px) {
    .testemunhos-sec .slider {
        width: 700px;
        margin: auto;
    }
}


@media only screen and (max-width: 1024px) {

    /* ------------------------------------------------------------ GERAL ------------------------------------------------------------ */
    h1 {
        font-size: 30px;
        font-weight: bold;
        color: #000;
        margin: 0px;
    }

    h2 {
        font-size: 20px;
    }

    .section-default-center {
        padding: 40px 10px;
    }

    .section-default-center.servicos-section {
        padding: 40px 10px;
    }

    .comofunciona-main {
        padding: 40px 10px;
    }

    .login-section {
        min-height: calc(100vh - 800px);
    }

    /* ------------------------------------------------------------ HOME - VANTAGENS ------------------------------------------------------------ */


    .coluna-vantagens-child {
        height: 130px;
        justify-content: center;
    }

    /* ------------------------------------------------------------ FORMULARIO ------------------------------------------------------------ */

    .form.main h1 {
        font-size: 22px;
        font-weight: bold;
        color: #5b5a5d;
        margin: 0px;
    }

    .input-telefone-indicativos .ant-select {
        width: 150px !important;
    }

    .sec-entrada-recolha {
        flex-direction: column;
    }

    .calendarioentrada-saida {
        top: 793px !important;
        left: 0px !important;
        transition: top 500ms !important;
    }

    /*  .sec-calendario{
        margin-left: -20px;
    } */

    /* .dia-destaque-calendario{
        margin-left: -20px !important;
    } */

    .sec-entrada,
    .sec-recolha,
    .col-sec-entrada-recolha {
        width: 100%;
    }

    /* .custom-select-ant .ant-select {
        width: 100% !important;
    } */

    .col-dados-reserva .ant-select-single {
        width: 170px !important;
    }

    .totaldareserva-m {
        display: flex;
        align-items: center;
    }

    .totaldareserva-m-col1 {
        width: 80%;
        justify-content: space-between;
        align-content: center;
        align-items: center;
    }

    .totaldareserva-m-col2 {
        /* width: 20%;
        justify-content: flex-end;
        align-content: center;
        align-items: center;
        display: flex; */
        width: 20%;
        justify-content: center;
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }


    /* ------------------------------------------------------------ RESUMO DO PEDIDO ------------------------------------------------------------ */

    .resumodopedio-bem-vindo-preco-m {
        border-radius: 10px;
        border: solid 1px #abc132;
        background-color: #abc132;
        position: sticky;
        box-sizing: border-box;
        left: 20px;
        margin-bottom: 20px;
        z-index: 3;
        display: block !important;
    }

    .resumodopedio-bem-vindo-preco-m h3 {
        padding: 0px;
        margin: 0px;
    }


    .resumodopedio-bem-vindo-m {
        padding: 20px;
    }

    .resumodopedio-bem-vindo-m h2 {
        font-size: 20px;
        text-align: center;
        color: #fff;
    }

    .resumodopedio-bem-vindo-preco-m .ant-spin {
        color: #fff;
    }

    .resumodopedio-bem-vindo-m p {
        text-align: center;
        margin-bottom: 0px;
        color: #fff;
    }

    .resumodopedio-bem-vindo-preco-m {
        padding: 20px;
    }

    .resumodopedio-bem-vindo-preco-m h2 {
        font-size: 18px;
        text-align: left;
        color: #fff;
    }

    .resumodopedio-bem-vindo-preco-m p {
        text-align: center;
        margin-bottom: 0px;
        color: #fff;
    }

    .imagem-do-carro {
        height: 150px;
    }

    .flex.state-cancelado {
        display: flex;
        flex-direction: column;
    }


    /* ESTADO DA RESERVA */
    .bloco-admin-alterarreserva {
        display: grid;
        grid-template-columns: repeat(1, 1fr) !important;
        align-items: start;
        justify-items: center;
        gap: 15px;
        grid-gap: 15px;
        padding-bottom: 0px;
    }

    .total-da-reserva-rapida{
        padding: 10px;
        position: sticky;
        top: 0px;
        z-index: 9999;
        background: #abc132;
    }

    .precototaldareserva-marcacaorapida{
        padding: auto;
    }

    .flex.btn-dashboard-bottom{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .estadosdareserva-sec {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        gap:15px;
        grid-gap:15px;
        width: 100%;
    }

    /* .estadosdareserva-sec-btn {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        gap:15px;
        grid-gap:15px;
    } */

    .estadosdareserva-col-btn{
        display: flex;
        flex-direction: column;
        gap:15px;
        grid-gap:15px;
        width: 100%;
    }

    .estadosdareserva-sec .mensagem-de-sucesso {
        width: 100%;
    }


    /* ------------------------------------------------------------ FOOTER ------------------------------------------------------------ */
    .footer-main-sec {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    /* ------------------------------------------------------------ HEADRER ------------------------------------------------------------ */
    .menu-main {
        align-items: center;
        justify-content: center;
        height: 25px;
    }

    .header-section {
        height: 45px;
    }

    .header-main .logotipo img {
        width: 110px;
    }

    .top-bar li a {
        font-size: 14px;
    }

    /* ------------------------------------------------------------ POPUP MENU ------------------------------------------------------------ */

    .menu-main-popup {
        display: flex;
        gap: 40px;
        grid-gap: 40px;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    /* .popup-main-sec {
        padding: 40px;
        gap: 40px;
        grid-gap: 40px;
        border-radius: 10px;
        border: solid 1px #bfbfbf;
        background-color: #fcfcfc;
        top: 40px;
        position: relative;
        width: 100%;
        margin: 0px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
*/
    .popup-main-sec button {
        font-weight: 500;
        font-size: 24px;
    }

    .popup-menu-canva .menu-main-popup a,
    .menu-main-popup .menu-label {
        text-decoration: none;
        color: #5b5a5d;
        font-weight: 500;
        font-size: 18px;
    }

    /* .popup-menu-canva {
        z-index: 9;
        background: #fff;
        position: absolute;
        width: 100%;
        height: calc(100vh - 128px);
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    } */

    .popup-menu-canva .menu-main.menu {
        flex-direction: column;
    }

    /* Estilos para o fundo escuro por trás do popup */
    /* .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 8;
    } */

    /* Estilos para o popup */
    .popup-menu-canva {
        position: fixed;
        top: 0px;
        left: 0;
        background: #fff;
        width: 100%;
        height: 100%;
        display: grid;
        align-items: center;
        justify-content: center;
        z-index: 10000;
        padding-top: 60px;
        z-index: 8;
    }

    .popup-main-sec {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .popup-main-sec .facebook {
        font-size: 25px;
        color: #abc132;
    }

    .slider-home-main .swiper-slide {
        width: 100% !important;
    }



    /* ------------------------------------------------------------ TOPBAR ------------------------------------------------------------ */
    .menu-main {
        display: flex;
        gap: 0px;
        justify-content: space-between;
        align-items: center;
    }

    /* .menu-main a {
        text-decoration: none;
        color: #000;
        font-weight: 500;
        font-size: 18px;
    } */



    /* ------------------------------------------------------------ SLIDER ------------------------------------------------------------ */
    .slider img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        cursor: grab;
    }


    /* ------------------------------------------------------------ DESTAQUE DA HOME ------------------------------------------------------------ */

    .destaque-reserva-col {
        flex-direction: column;
        padding: 20px;
    }

    .destaque-reserva-main .col-frase-destaque {
        width: 100%;
        text-align: center;
    }

    .destaque-reserva-main .col-cta {
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .destaque-reserva-main h1 {
        color: #fff;
        font-size: 26px;
    }

    .destaque-reserva-main {
        height: 165px;
    }

    /* ------------------------------------------------------------ SOBRENOS ------------------------------------------------------------ */
    .coluna-sobre-nos-child {
        align-items: center;
    }

    .coluna-sobre-nos-child h4,
    .coluna-sobre-nos-child h1 {
        text-align: center;
    }

    .sobre-nos-section p {
        font-size: 16px;
    }


    .sobre-nos-section p {
        padding-top: 0px;
        text-align: center;
    }

    /* ------------------------------------------------------------ NOSSOS SERVIÇOS ------------------------------------------------------------ */
    .coluna-servicos {
        flex-direction: column;
    }

    /* ------------------------------------------------------------ DASHBOARD ------------------------------------------------------------ */
    .ant-table-content {
        width: 100%;
        overflow-x: auto;
    }

    .imagens-do-carro {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    .videos-do-carro-main {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }

    .subcol-dados-local-estacionamento {
        flex-direction: column;
    }

    /* ------------------------------------------------------------ COMOFUNCIONA ------------------------------------------------------------ */
    .coluna-comofunciona-center h1 {
        width: 100%;
    }

    /* ------------------------------------------------------------ FAQS ------------------------------------------------------------ */
    .coluna-default-left {
        flex-direction: column;
    }

    /* ------------------------------------------------------------ TESTEMUNHOS ------------------------------------------------------------ */
    /* .testemunhos-main .swiper-slide {
        margin: 0px !important;
    } */

    .testemunhos-col {
        width: 70%;
        margin: auto;
    }

    .swiper-button-prev,
    .swiper-button-next {
        top: 110%;
    }


    .testemunhos-sec {
        padding: 40px 20px 80px 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        grid-gap: 20px;
    }

    .testemunhos-main .slider {
        width: 70%;
        max-width: 85%;
    }

    .coluna-sobre-nos-child {
        width: 100%;
    }

    #faqs .coluna-sobre-nos-child h4,
    #faqs .coluna-sobre-nos-child h1 {
        text-align: center;
    }

    .ant-collapse.ant-collapse-icon-position-start {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) {

    .footer-main-sec {
        justify-items: center;
        grid-template-columns: repeat(1, 1fr) !important;
        text-align: center;
    }

    .footer-copyright {
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    .col-dados-reserva .ant-select-single {
        width: 100% !important;
    }

    .sec-entrada,
    .sec-recolha,
    .col-sec-entrada-recolha,
    .custom-select-ant {
        width: 100%;
    }

    .custom-select-ant .ant-select {
        width: 100% !important;
    }

    /* ------------------------------------------------------------ LOGIN ------------------------------------------------------------*/
    .mensagem-de-sucesso {
        flex-direction: column;
        gap: 15px;
        grid-gap: 15px;
    }

    .mensagem-de-sucesso p,
    .mensagem-de-erro p {
        text-align: center;
    }

    .mensagem-de-erro {
        flex-direction: column;
        gap: 10px;
        grid-gap: 10px;
    }

    .loading-sec-baixo.mensagem-de-sucesso.fundo-branco .anticon-loading,
    .mensagem-de-erro svg,
    .mensagem-de-sucesso svg {
        margin: 0px !important;
    }

    .mensagem-de-sucesso svg {
        font-size: 30px !important;
    }

    /* ------------------------------------------------------------ DADOS DE PAGAMENTO ------------------------------------------------------------*/

    .col-dados-pagamemento {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        gap: 10px;
    }

    .mensagem-de-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        gap: 30px;
        grid-gap: 30px;
    }

    .mensagem-de-loading span {
        margin: 0px;
    }

    .mensagem-de-loading svg {
        font-size: 30px !important;
        color: #ffbb00;
    }

    /* ------------------------------------------------------------ FAQ's PAGE ------------------------------------------------------------*/

    .faqs-main-sec {
        display: grid;
        width: 100%;
        gap: 40px;
    }


    .mensagem-de-aviso {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        gap: 30px;
        grid-gap: 30px;
    }

    .mensagem-de-aviso span {
        margin: 0px;
    }

    .mensagem-de-aviso svg {
        font-size: 30px !important;
        color: #ffbb00;
    }



    /* ------------------------------------------------------------ CALENDARIO ------------------------------------------------------------*/


    .destaque-diaselecionado {
        font-size: 23px;
        text-align: left;
        display: flex;
        align-content: center;
        align-items: center;
        gap: 10px;
        grid-gap: 10px;
        justify-content: space-between;
    }

    .dia-destaque-calendario .custo-do-dia {
        text-align: left;
        padding: 0px;
        margin: 0px;
        font-size: 23px;
        font-weight: normal;
    }

    .destaque-diaselecionado .flex {
        gap: 10px;
        grid-gap: 10px;
    }

    .sec-close-calendario {
        background: #abc132;
        border-radius: 5px;
        padding: 5px;
        text-align: center;
        border: 2px solid;
    }

    .btn-close-calendario img {
        width: 17px;
        cursor: pointer;
        position: relative;
        top: 2px;
    }

    .dia-destaque-calendario {
        display: flex;
        flex-direction: column;
        gap: 20px;
        grid-gap: 20px;
    }

}


/* ---------------------------------------- MOBILE ---------------------------------------- */

@media only screen and (max-width: 1024px) {


    .marcacao-rapida .calendarioentrada-open{
        top: 89px !important;
    }

    .marcacao-rapida .calendarioentrada-saida{
        top: 590px !important;
    }

    .servicos-adicionais-reserva{
        display: flex;
        gap: 10px;
        grid-gap: 10px;
        flex-direction: column;
        align-items: flex-start;
    }

    .label-servicos-adicionais-reserva{
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
    }

    .bloco-calendario-main-col {
        left: 0px;
        /* margin: 0px 20px; */
    }

    .calendario {
        top: 283px;
        width: calc(100% - 40px);
        flex-direction: column;
        margin: 0px 20px;
    }

    .form.main {
        padding: 10px;
    }

    .ticket-resumodopedido {
        display: none !important;
    }

    .sec-entrada-recolha {
        flex-direction: column;
    }

    .sec-entrada,
    .sec-recolha {
        width: 100%;
    }


    .flex-col-espaco {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        grid-gap: 20px;
    }

    .col-dados-pessoais {
        flex-direction: column;
    }

    .subcol-dados-pessoais input {
        width: 100%;
        box-sizing: border-box;
    }

    .col-email-dados-pessoais {
        width: 100%;
    }

    .input-matricula {
        width: 100% !important;
    }

    .col-detalhes-do-voo {
        flex-direction: column;
        gap: 20px;
        grid-gap: 20px;
    }

    .col-dados-reserva {
        flex-direction: column;
    }

    .subcol-dados-reserva input,
    .selecao-da-marca-carro {
        width: 100%;
        box-sizing: border-box;
    }

    .col-extraxmonopicks {
        flex-direction: column;
    }

    .col-extras {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        font-size: 18px;
    }

    .col-extras .label-do-campo {
        font-size: 18px;
    }

    .submeterreserva {
        width: 100%;
    }

    .dia-destaque-calendario {
        margin-left: 0px !important;
        border-radius: 9px !important;
        height: auto !important;
        width: auto !important;
        text-align: end !important;
    }

    .btn-close-calendario {
        position: relative !important;
        right: 0px !important;
    }

    .calendarioentrada-open {
        transition: top 500ms !important;
    }

    .precototaldareserva {
        text-align: right !important;
    }

    .resumo-pedido-main-sec-m {
        background: rgb(255, 255, 255);
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 54%, rgba(251, 252, 252, 0) 100%) !important;
        position: sticky;
        display: block !important;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 7;
    }

    .flex-resumo-page {
        flex-direction: column !important;
        gap: 20px !important;
        grid-gap: 20px !important;
    }

    .flex-resumo-page-col {
        width: 100% !important;
    }

}

@media only screen and (max-width: 900px) and (min-width: 786px) {
    .calendarioentrada-open {
        left: 0px !important;
        transition: none !important;
    }

    .calendarioentrada-saida {
        left: 0px !important;
        transition: none !important;
    }
}




/* ---------------------------------------- END - MOBILE ---------------------------------------- */