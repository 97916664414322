/* ---------------------------------------- GERAL ---------------------------------------- */


.loading-sec {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-spin {
  color: #abc132;
}


/* ---------------------------------------- SERVICOS ---------------------------------------- */

.servicos-loading {
  height: 538px;
}



/* ---------------------------------------- MENSAGEM DE SUCESSO ---------------------------------------- */

.mensagem-de-sucesso {
  border: 1px solid #ABC132;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
}

.mensagem-de-sucesso p {
  font-size: 16px;
  font-weight: 500;
  color: #ABC132;
  padding: 0px;
  margin: 0px;
}

.mensagem-de-sucesso svg {
  font-size: 30px;
  color: #ABC132 !important;
}

.mensagem-de-sucesso img {
  object-fit: contain;
}

.loading-sec-baixo.fundo-branco svg {
  color: #ABC132;
}

.sec-servicosextrareserva .msg-sec{
  margin-top: 20px;
}

/* .loading-sec-baixo.mensagem-de-sucesso.fundo-branco .anticon-loading {
  font-size: 30px !important;
  margin-left: 20px !important;
} */

/* .mensagem-de-sucesso .registar-label {
    color: #ABC132 !important;
  } */

/* ---------------------------------------- END - MENSAGEM DE SUCESSO ---------------------------------------- */

/* ---------------------------------------- MENSAGEM DE ERRO ---------------------------------------- */

.mensagem-de-erro {
  display: flex;
  border: 1px solid #fd595e;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
}

.mensagem-de-erro p {
  font-size: 16px;
  font-weight: 500;
  color: #fd595e !important;
  padding: 0px;
  margin: 0px;
}

.mensagem-de-erro svg {
  font-size: 30px;
  color: #fd595e !important;
}

/* ---------------------------------------- END - MENSAGEM DE ERRO ---------------------------------------- */


/* ---------------------------------------- MENSAGEM DE LOADING ---------------------------------------- */

.mensagem-de-loading {
  border: 1px solid #ffbb00;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
}

.mensagem-de-loading p {
  font-size: 16px;
  font-weight: 500;
  color: #ffbb00;
  padding: 0px;
  margin: 0px;
}

.mensagem-de-loading span {
  color: #ffbb00 !important;
}

.mensagem-de-loading img {
  object-fit: contain;
}

.loading-sec-baixo.fundo-branco svg {
  color: #ffbb00;

}

/* ---------------------------------------- END - MENSAGEM DE LOADING ---------------------------------------- */

.loading-home {
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  ;
  justify-content: center !important;
  ;
  align-content: center !important;
  ;
}

/* ---------------------------------------- MENSAGEM DE AVISO ---------------------------------------- */

.mensagem-de-aviso {
  border: 1px solid #ffbb00;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.inserir-imagens-grupo .mensagem-de-aviso svg {
  font-size: 30px;
  color: #ffbb00;
}

.mensagem-de-aviso p {
  font-size: 16px;
  font-weight: 500;
  color: #ffbb00;
  padding: 0px;
  margin: 0px;
}

.mensagem-de-aviso span {
  margin-left: 20px;
  color: #ffbb00 !important;
}

.mensagem-de-aviso img {
  object-fit: contain;
}

.loading-sec-baixo.fundo-branco svg {
  color: #ffbb00;

}

/* ---------------------------------------- END - MENSAGEM DE AVISO ---------------------------------------- */


.loading-sec-dias {
  height: 365px;
  display: flex;
  align-items: center;
}


.loading-normal {
  display: flex;
  height: 40vh;
  justify-content: center;
  align-content: center;
  align-items: center;
}