body {
    margin: 0;
    /* padding: 40px 20px; */
    background-color: #fff;
    font-family: 'Lato', sans-serif;
    color: #5b5a5d;
    /* color:#000; */
    margin: auto;
    /* border-top: 60px solid #abc132; */
    overflow-x: hidden;
}

h1 {
    font-size: 34px;
    font-weight: bold;
    color: #000;
    margin: 0px;
}

h2 {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: normal;
    text-align: left;
    color: #abc132;
    text-transform: uppercase;
    margin: 0px;
}

/* h3{
    color: #5b5a5d;
} */

h4 {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin: 0px;
    /* text-transform: uppercase; */
}


label {
    cursor: pointer;
}

.button-geral {
    border-radius: 5px;
    border: solid 1px #bfbfbf;
    width: 120px;
    height: 46px;
    background-color: #bfbfbf;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5b5a5d;
    font-weight: 500;
    cursor: pointer;
}

.button-com-fundo {
    border-radius: 5px;
    width: 176px;
    height: 46px;
    background-color: #fff;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ABC132;
    font-weight: 500;
    cursor: pointer;
    border: 0px;
}

.button-sem-fundo {
    border-radius: 5px;
    width: 176px;
    height: 46px;
    background-color: #ABC132;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px;
}

.button-geral:hover {
    background-color: #ABC132;
    color: #fff;
}

.titulo-de-destaques {
    text-transform: uppercase;
}

.texto-centro {
    text-align: center;
}

ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}


.flex {
    display: flex;
    align-content: center;
    align-items: center;
    gap: 30px;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.flex-nogap {
    display: flex;
    align-content: center;
    align-items: center;
}

.section-main {
    max-width: 2000px;
    margin: auto;
}

.input-geral {
    height: 48px;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    border: solid 1px #bfbfbf;
    box-sizing: border-box;
    padding: 0px 10px;
    font-size: 16px;
}

.text-cinza {
    color: rgba(0, 0, 0, 0.25);
}

.sec-geral-top {
    border-top: 40px solid #abc132;
    padding: 40px 20px;
}

.estadosdareserva-sec {
    display: flex;
    gap: 15px;
    grid-gap: 15px;
    flex-direction: column;
}


.estadosdareserva-col-btn {
    display: flex;
    flex-direction: row;
    gap: 15px;
    grid-gap: 15px;
}


.label-link {
    color: #5b5a5d;
}

.label-link-verde {
    color: #ABC132;
}

.label-link-verde:hover {
    color: #ABC132;
    text-decoration: underline;
}




.nome-do-utilizador {
    color: #ABC132;
}

.funcao-do-utilizador {
    color: #ffffff;
    font-size: 14px;
    background: #979698;
    padding: 5px;
    border-radius: 3px;
}

.cump-ao-utilizador .cump-ao-utilizador-c {
    text-align: end;
}

/* .loading-sec-baixo {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.loading-sec-baixo.fundo-verde svg {
    color: #fff;
}

.loading-sec-baixo.fundo-branco {
    color: #ABC132 !important;
}


.rc-virtual-list-scrollbar-thumb {
    background: #ABC132 !important;
}

.fraseinformativa {
    text-align: center;
    color: #5b5a5d;
    font-size: 12px;
    font-weight: 600;
    margin-top: -10px;
}

/* ---------------------------------------- SECTION  ---------------------------------------- */

.section-default {
    display: flex;
    gap: 30px;
    /* max-width: 1400px; */
    padding: 40px 20px;
    margin: auto;
}

.section-default-center {
    display: grid;
    flex-direction: column;
    align-content: center;
    /* justify-content: center; */
    gap: 40px;
    grid-gap: 40px;
    max-width: 1600px;
    padding: 80px 10px;
    margin: auto;
}

.section-default-center-dois {
    max-width: 1600px;
    margin: auto;
}


.section-default-center.sobre-nos-section {
    padding: 40px 10px;
}

.vantagens-main {
    padding: 0px 10px 80px 10px !important;
}

.coluna-default {
    width: 50%;
}

.coluna-default-center {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-gap: 20px;
}

.coluna-default-left {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.imagem-default {
    width: 100%;
    border-radius: 10px;
}


/* ---------------------------------------- SOBRE NOS  ---------------------------------------- */


.sobre-nos-section p {
    color: #000000;
    font-size: 20px;
}

.sobre-nos-descricao a {
    color: #ABC032;
}

.sobre-nos-section .imagem-default {
    height: 500px;
    width: 100%;
    object-fit: cover;
}

.coluna-sobre-nos-child {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sobre-nos-descricao {
    width: 70%;
}


/* ----------------------------------------  VANTAGENS  ---------------------------------------- */


.coluna-vantagens {
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
}

.vantagens-main {
    padding: 10px;
}

.coluna-vantagens-child {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    background: #ABC132;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}


/* ----------------------------------------  SERVICOS  ---------------------------------------- */

.coluna-servicos {
    display: flex;
    gap: 40px;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
}

.section-default-center.servicos-section {
    padding: 80px 20px;
}

.coluna-servicos-child {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 40px 20px;
    background: #ABC132;
    display: flex;
    flex-direction: column;
    color: #fff;
    gap: 20px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.coluna-servico-exterior {
    background: #58585A;
    color: #fff;
}

.coluna-servico-exterior h1 {
    color: #ABC132 !important;
}

.servicos-section .coluna-servicos {
    text-align: center;
}

.coluna-servicos h3 {
    text-align: center !important;
    font-size: 20px;
    padding: 0px;
    margin: 0px;
}

.coluna-servicos p {
    text-align: center !important;
    font-size: 20px;
    padding: 0px;
    margin: 0px;
}

.coluna-servicos span {
    text-align: center !important;
    font-size: 20px;
    font-weight: 500;
    padding: 0px;
    margin: 0px;
}

.coluna-servicos h1 {
    text-align: center !important;
    font-size: 70px;
    color: #58585A;
    padding: 0px;
    margin: 0px;
}




/* ----------------------------------------  COMO FUNCIONA  ---------------------------------------- */

.comofunciona-main {
    padding: 10px;
}


.comofunciona-section {
    background: #5b5a5d;
    border-radius: 20px;
    padding: 40px 10px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    grid-gap: 40px;
}

.coluna-comofunciona {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
}

.coluna-comofunciona-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    grid-gap: 40px;
    max-width: 1050px;
    margin: auto;
}

.coluna-comofunciona-title h1 {
    color: #fff !important;
    text-align: center;
}

.coluna-comofunciona-title h4 {
    color: #fff !important;
    text-transform: uppercase;
}


.coluna-comofunciona p {
    color: #fff !important;
    text-align: center;
}

.coluna-comofunciona .imagem-default {
    height: 56px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 8px;
}

/* 
.coluna-comofunciona-center h1 {
    color: #fff !important;
    font-size: 30px;
    width: 700px;
    margin: auto;
}

.coluna-comofunciona-center h4 {
    color: #fff !important;
}

.coluna-comofunciona-child {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 50%;
    box-sizing: border-box;
}

.coluna-comofunciona-child p {
    color: #fff !important;
    padding: 0px;
    margin: 0px;
}

.comofunciona-section .imagem-default {
    height: 56px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 8px;
}

.comofunciona-section .coluna-comofunciona {
    text-align: center;
}

.coluna-comofunciona h2 {
    text-align: center !important;
    color: #5b5a5d;
    font-size: 20px;
}

.comofunciona-main .swiper{
    color: #fff;
}

.comofunciona-img {
    width: 100px;
    height: 40px;
    object-fit: cover;
} */

/* ----------------------------------------  PÁGINA DO RESUMO DA RESERVA  ---------------------------------------- */

.imagem-do-carro {
    width: 100%;
    height: 232px;
    object-fit: cover;
}

.uppercase-element {
    text-transform: uppercase;
}

.imagens-do-carro {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    /* height: 500px;
    overflow: scroll; */
}

.inserir-imagens-grupo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}


.subcol-dados-local-estacionamento {
    display: flex;
    gap: 20px;
    align-items: center;
}

.mainsec-dados-local-estacionamento .local-de-estacionamento {
    font-weight: 400;
}

.estadosdareserva-sec .label-estadodareserva {
    font-weight: 400;
}

/* .estadosdareserva-sec {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
} */

.estadosdareserva-col {
    display: flex;
    gap: 10px;
    grid-gap: 10px;
}

.estadosdareserva-col h3 {
    margin-bottom: 0px !important;
}

/* .bloco-admin-alterarreserva {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    align-items: start;
    gap:15px;
    grid-gap:15px;
    padding-bottom: 20px;
} */

.estadosdareserva-sec h3 {
    margin: 0px;
    padding-bottom: 15px;
    padding-top: 12px;
}

.estadosdareserva-sec .mensagem-de-sucesso,
.estadosdareserva-sec .mensagem-de-erro {
    padding: 8px;
}

.selecao-da-local-estacionamento {
    padding: 12px 16px;
    border-radius: 9px;
    border: solid 1px #bfbfbf;
    background-color: #fff;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.4px;
    color: #bfbfbf;
    width: 172px;
}

.atualizarestacionamento {
    cursor: pointer !important;
}

.atualizarestacionamento.atualizarestacionamento-carregar {
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.submeterimagens {
    border-radius: 5px;
    border: solid 1px #bfbfbf;
    width: 126px;
    height: 46px;
    background-color: #bfbfbf;
    font-size: 15px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5b5a5d;
    font-weight: 600;
    cursor: pointer;
}


.videos-do-carro-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
}

.videos-do-carro {
    width: 100%;
}

.ant-tabs {
    color: #5b5a5d;
}

.ant-btn-primary {
    background-color: #ABC032;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #58585A !important
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border-color: #ABC032;
    color: #ABC032;
}

.admin-lab {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #bfbfbf;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-gap: 20px;
}

.cliente-lab {
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid #bfbfbf;
    padding: 20px;
}

.cliente-lab h3 {
    margin-bottom: 1em;
    margin-top: 0px;
}

.admin-lab h3 {
    margin-bottom: 0em;
    margin-top: 0px;
}

.ant-image-mask-info {
    display: none;
}

/* .numero-de-voo-func span {
    text-transform: uppercase;
} */


.sec-estadopagamento-serextrares {
    width: 100%;
}


.modal-com-flex {
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-gap: 20px;
}



/* ----------------------------------------  PÁGINA DE DADOS DE PAGAMENTO  ---------------------------------------- */

.sec-dadospagamento {
    margin-top: 40px;
}

.sec-pagamentos {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-direction: column;
    gap: 20px;
    grid-gap: 20px;
}

.pagamentos-section {
    min-height: calc(100vh - 500px);
}

.col-dados-pagamemento {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    gap: 20px;
}

.subcol-dados-pagamento .input-calendario {
    box-sizing: border-box !important;
}

.ant-select-selection-item {
    color: #000;
}

.paises-select {
    width: 100%;
}

.pagamentos-section button {
    border-radius: 5px;
    width: 176px;
    height: 46px;
    background-color: #abc132;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px;
}

.pagamentos-section button:hover {
    background-color: #5B5A5D !important;
    color: #fff !important;
}


.ant-input:hover,
.ant-input:focus {
    border-color: #ABC032;
    outline-color: #ABC032;
}

.ant-input {
    padding: 10px;
}

.motivo-do-cancelamento {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.motivo-do-cancelamento-box-cancleado {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.motivo-do-cancelamento-box {
    border: 1px solid #BFBFBF;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.motivo-do-cancelamento-box a {
    color: #000;
}

.motivo-do-cancelamento-box .ant-btn {
    display: flex;
}

.bloco-motivodocancelamento {
    display: flex;
    gap: 10px;
    grid-gap: 10px;
    flex-direction: column;
}

.edit-form-button {
    border-radius: 5px;
    background-color: #BFBFBF;
    color: #fff;
    cursor: pointer;
    border: 0px solid;
}

.edit-form-button:hover {
    background-color: #9a9a9a !important;
}

.motivo-do-cancelamento-box .ant-btn-icon {
    font-size: 20px;
}

.motivo-do-cancelamento-box p {
    margin: 0px;
}

/* .col-sec-dadospagamento {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    margin-top: 30px;
}

.col-dados-pagamento-sec {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    gap:30px;
    width: 100%;
} */

/* 
.selecao-do-metododepagamento {
    padding: 12px 16px;
    border-radius: 9px;
    border: solid 1px #bfbfbf;
    background-color: #fff;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.4px;
    color: #bfbfbf;
    width: 100%;
    height: 48px;
}

.col-sec-dadospagamento .input-calendario{
    box-sizing: border-box !important;
}



.col-sec-dadospagamento label, .col-sec-dadospagamento label{
    width: 100%;
}

.pagamentos-section {
    min-height: calc(100vh - 500px);
}


.col-dados-pagamento-sec {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    gap:30px;
    width: 100%;
} */


/* ----------------------------------------  LOGIN  ---------------------------------------- */


.login-section {
    min-height: calc(100vh - 500px);
}

#components-form-demo-normal-login .login-form {
    max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
    float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
    float: left;
}

#components-form-demo-normal-login .login-form-button {
    width: 100%;
}

.login-form-button:hover,
.login-form-button-pequeno:hover {
    background: #ABC032 !important;
}

.login-form-button-f-verde:hover {
    background: #58585A !important;
}

.login-form-button-f-branco:hover {
    background: #fff !important;
    color: #ABC032 !important;
}

.sobre-form-button-f-branco {
    background: #ABC032 !important;
    color: #fff !important;
}

.sobre-form-button-f-branco:hover {
    background: #58585A !important;
    color: #fff !important;
}

.enviar-form-button {
    background: #BFBFBF;
    border-radius: 5px;
    /* width: 120px; */
    height: 43px;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px solid;
}

.login-form-button {
    border-radius: 5px;
    /* width: 120px; */
    height: 46px;
    background-color: #ABC132;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px solid;
}

.carrinho-form-button {
    border-radius: 5px;
    background-color: #ABC132;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px solid;
}

.carrinho-form-button:hover {
    background-color: #ABC132 !important;
}

.back-form-button {
    border-radius: 5px;
    width: 120px;
    height: 46px;
    background-color: #ABC132;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-form-button svg {
    font-size: 20px;
}


.admin-form-button-n {
    border-radius: 5px;
    height: 40px;
    background-color: #58585A;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px solid;
}

.admin-form-button-n:hover {
    background-color: #ABC132 !important;
}



.cancelar-form-button {
    border-radius: 5px;
    width: 120px;
    height: 46px;
    background-color: #fff;
    border: 2px solid #BFBFBF;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #BFBFBF;
    font-weight: 500;
    cursor: pointer;
}

.cancelar-form-button:hover {
    background-color: #ff595e !important;
    color: #fff;
}

.estadosdareserva-sec .ant-select {
    margin: 0px !important;
}

.atualizar-form-button {
    border-radius: 5px;
    width: 120px;
    height: 46px;
    /* background-color: #BFBFBF; */
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px solid;
}

.totaldareserva-marcacao {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.total-da-reserva-rapida {
    padding: 30px 60px;
    max-width: 800px;
    margin: auto;
    border-radius: 10px;
    border: solid 1px #bfbfbf;
    background-color: #abc132;
    gap: 50px;
    grid-gap: 50px;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0px;
    z-index: 99;
}

.atualizar-form-button:hover {
    background-color: #BFBFBF !important;
    color: #fff;
}

.login-form-button-pequeno {
    background: #BFBFBF;
    border-radius: 5px;
    width: 150px;
    height: 46px;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px solid;
}

.login-sair-form-button {
    background: #fff;
    border-radius: 5px;
    /* width: 140px; */
    height: 40px;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #BFBFBF;
    font-weight: 500;
    cursor: pointer;
    border: 2px solid #BFBFBF;
}

.login-sair-form-button:hover {
    background: #BFBFBF !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #ABC032;
}

/* ----------------------------------------  DASHBOARD  ---------------------------------------- */

.dashboard-section {
    min-height: calc(100vh - 500px);
}

.ant-pagination-options {
    display: none !important;
}

.ant-pagination-item-link-icon {
    color: #ABC032 !important;
}

.reserva-utilizador {
    color: #5b5a5d !important;
    text-decoration: none;
    font-size: 18px;
}

.reserva-utilizador:hover {
    text-decoration: underline;
}

.cump-ao-utilizador h3 {
    margin: 0px;
}

.link-label {
    color: #ABC132 !important;
}

.link-label-dashboard {
    color: rgba(0, 0, 0, 0.88);
}

.link-label-dashboard:hover {
    color: rgba(0, 0, 0, 0.88);
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ABC132;
}

.ant-tabs .ant-tabs-tab:hover {
    color: #ABC132;
}

.ant-pagination .ant-pagination-item-active a {
    color: #abc132;
}

.ant-pagination .ant-pagination-item-active:hover {
    border-color: #abc132;
}

.ant-pagination .ant-pagination-item-active {
    border-color: #abc132;
}

.ant-pagination .ant-pagination-item-active:hover a {
    color: #abc132;
}

.ant-tabs .ant-tabs-ink-bar {
    background: #abc132;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: #abc132;
    outline: #abc132 auto 1px;
}

.estado-reserva.aberto {
    color: #ABC132;
}

.estado-reserva.cancelado {
    color: #ff595e;
}

.estado-reserva.reserva {
    color: #000;
}

.estado-reserva.fechado {
    color: #ff595e;
}

.acesso-negado {
    min-height: calc(100vh - 500px);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.acesso-negado svg {
    font-size: 50px;
    color: #ff595e;
}

.faca-a-sua-reserva {
    min-height: calc(100vh - 500px);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.faca-a-sua-reserva svg {
    font-size: 50px;
    color: #ABC132;
}

/* .campo-ajuste-de-reserva{
    width: 100%;
}

.label-campo-ajuste-de-reserva{
    margin-top: 10px;
}

.label-campo-ajuste-de-reserva .input-calendario{
    height: 35px;
    margin-left: 10px;
} */


.ajustar-reserva-input {
    margin-top: 10px;
    margin-bottom: 10px;
}


.cump-ao-utilizador {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


/* ----------------------------------------  ERRO 404  ---------------------------------------- */


.erropagina-section {
    min-height: calc(100vh - 500px);
}

.acesso-negado .flex-nogap {
    flex-direction: column;
}



/* ---------------------------------------- FAQs PAGINA ---------------------------------------- */

.faqs-main-sec {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
}

.ant-collapse-content-box a {
    color: #ABC032;
    text-decoration: underline;
}





/* ------------------------------------------------------------------------------- */





.sec-servicosextrareserva {
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-gap: 20px;
}


.extraservico-pago {
    color: #ABC032;
    font-size: 23px;
    display: flex;
}

.extraservico-nao-pago {
    color: #ff595e;
    font-size: 23px;
    display: flex;
}

.sec-estadopagamento-serextrares .flex {
    display: flex;
    gap: 10px;
    grid-gap: 10px;
}

.informacoesdareserva-num-estado {
    display: flex;
    flex-direction: column;
    gap: 12px;
    grid-gap: 12px;
}


.editar-dias-reserva {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 10px;
}

.icon-editar-dias-reserva {
    display: flex;
    font-size: 18px;
    cursor: pointer;
}



.eliminar-imagem{
    position: absolute;
    color: #fff;
    background-color: #58585A;
    font-size: 15px;
    padding: 3px 10px;
    border-radius: 0px 0px 5px 0px;
    left: 0;
}

.eliminar-imagem:hover{
    color: #fff;
    background-color: #ff595e;
}

.sec-image-single{
    display: block;
    position: relative;
}