/* ---------------------------------------- HEADER ---------------------------------------- */

.header-main {
    max-width: 1800px;
    margin: auto;
    position: relative;
    z-index: 9;
}

.header-section {
    height: 60px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.header-main .logotipo img {
    width: 130px;
}


.header-main button {
    border-radius: 5px;
    width: 120px;
    height: 46px;
    background-color: #ABC132;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px solid;
}

.header-main button:hover{
    background-color: #58585A !important;
    color: #fff !important;
}

.erropagina-section button {
    border-radius: 5px;
    height: 46px;
    background-color: #ABC132;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px solid;
}

.erropagina-section button:hover{
    background-color: #58585A !important;
    color: #fff !important;
}

.header-main .menu a {
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

/*-top-bar-*/

.top-bar {
    background: #ABC132;
    padding: 10px 10px;
    color: #fff;
    position: relative;
    z-index: 9;
}

.tor-bar-section {
    max-width: 1800px;
    margin: auto;
}

.top-bar li {
    display: flex;
    flex-direction: column;
}

.top-bar li span {
    font-size: 10px;
}

.top-bar li a {
    color: #fff;
    display: flex;
    align-items: center;
    /* font-size: 16px; */
}

/* .usernamelogin {
    color: #5b5a5d !important;
    font-weight: 600 !important;
} */

.user-login-icon {
    font-size: 25px;
    background: #fff;
    color: #58585a;
    border-radius: 50px;
    padding: 3px;
}

.user-login-icon-logado {
    font-size: 20px;
    background: #5b5a5d;
    border-radius: 50px;
    padding: 3px;
}





/* ---------------------------------------- MENU ---------------------------------------- */


.menu-main {
    display: flex;
    gap: 40px;
    grid-gap: 40px;
    justify-content: flex-end;
    align-items: center;
}

.menu-main li{
    cursor: pointer;
}

.menu-main a {
    text-decoration: none;
    color: #5b5a5d;
    font-weight: 500;
    font-size: 16px;
}

.icon-user-login{
    display: flex;
    align-items: center;
}

/* ---------------------------------------- HAMBURGER ---------------------------------------- */

.hamburger {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 10;
    cursor: pointer;
    align-items: center;
    overflow: hidden;
}

.burger {
    width: 22px;
    height: 0.20rem;
    border-radius: 10px;
    background-color: #5b5a5d;
    transform-origin: 1px;
    transition: all 0.3s linear;
}


/* ---------------------------------------- FOOTER ---------------------------------------- */


.footer-main {
    padding: 40px 10px;
    background: #abc132;
    color: #fff;
}

.footer-main a {
    color: #fff;
    text-decoration: none;
}

.footer-contactos p {
    font-size: 12px;
    margin: 0px;
}

.footer-copyright {
    font-size: 12px;
}

.footer-copyright a {
    text-decoration: underline;
}

.footer-main-sec {
    max-width: 1800px;
    display: grid;
    margin: auto;
    gap: 30px;
    grid-gap: 30px;
    /* justify-items: center; */
    grid-template-columns: repeat(4, 1fr);
}

.footer-copyright {
    padding-top: 20px;
    max-width: 1800px;
    display: flex;
    align-items: flex-start;
    margin: auto;
    justify-content: space-between;
    gap: 30px;
    grid-gap: 30px;
    margin-top: 30px;
}

.footer-main-sec .logotipo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-gap: 20px;
}

.footer-main-sec .logotipo .facebook {
    font-size: 25px;
}

.footer-main-sec .logotipo img {
    width: 200px;
}

/* ---------------------------------------- DESTAQUE RESERVA ---------------------------------------- */

.destaque-reserva-main {
    padding: 10px;
    height: 90px;
}

.destaque-reserva-col {
    padding: 40px;
    max-width: 1050px;
    background: #abc132;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    top: -80px;
    z-index: 1;
    display: flex;
    align-content: center;
    align-items: center;
    gap: 30px;
    grid-gap: 30px;
    /* margin: 0px 20px; */
}

.destaque-reserva-main .col-frase-destaque {
    width: 70%;
}

.destaque-reserva-main .col-cta {
    width: 30%;
    display: flex;
    justify-content: flex-end;
}

.destaque-reserva-main h1 {
    color: #fff;
    font-size: 40px;
}

.destaque-reserva-main button, .servicos-main button{
    border-radius: 5px;
    width: 176px;
    height: 46px;
    background-color: #58585A;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px;
}


.destaque-reserva-main button{
    border-radius: 5px;
    width: 176px;
    height: 46px;
    background-color: #fff;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #abc132;
    font-weight: 500;
    cursor: pointer;
    border: 0px;
}


.faqs-main button{
    border-radius: 5px;
    width: 176px;
    height: 46px;
    background-color: #abc132;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px;
}

.faqs-main button:hover{
    background-color: #58585A !important;
    color: #fff !important;
}


.coluna-servico-exterior button{
    background-color: #abc132 !important;
    color: #fff !important;
}

.coluna-servico-exterior button:hover{
    background-color: #fff !important;
    color: #abc132 !important;
}

.faqs-main button{
    border-radius: 5px;
    width: 176px;
    height: 46px;
    background-color: #abc132;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px;
}


/* ---------------------------------------- SELECT DA RESERVA ---------------------------------------- */

.ant-select-single .ant-select-selector {
    align-items: center;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    border-radius: 9px;
    border: solid 1px #bfbfbf;
    background-color: #fff;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.4px;
    color: #bfbfbf;
}



/* ---------------------------------------- FIM - SELECT DA RESERVA ---------------------------------------- */




/* ---------------------------------------- TESTEMUNHOS ---------------------------------------- */

/* .testemunhos-main .slider {
    max-width: 600px;
    margin: auto;
    text-align: center;
}

.testemunhos-main .slider img {
    height: 150px;
    width: 150px;
    object-fit: contain;
    border-radius: 10px;
} */

.testemunhos-sec{
    display: flex;
    flex-direction: column;
    gap: 40px;
    grid-gap: 40px;

}


.testemunhos-main-sec .swiper-wrapper{
    align-items: center;
}

@media only screen and (min-width: 1025px) {

    .testemunhos-sec{
        padding: 80px 20px;
    }

    .testemunhos-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        gap: 20px;
        grid-gap: 20px;
        padding-bottom: 40px;
        padding-top: 20px;
        width: 70%;
        margin: auto;
    }
}

.testemunhos-nome {
    font-size: 20px;
    text-align: center;
    color: #000;
    margin: 20px;
    padding: 0px;
}

.testemunhos-descricao {
    margin: 0px;
    text-align: center;
    padding: 0px;
    color: #000;
    font-size: 14px;
}

.testemunhos-main-sec .slider .swiper-button-next:after,
.testemunhos-main-sec .slider .swiper-button-prev:after {
    color: #000 !important;
    font-size: 20px;
}

.testemunhos-imagem{
    height: 120px !important;
    width: 100% !important;
    object-fit: contain !important;
}

/* .testemunhos-sec .slider{
    width: 700px;
    margin: auto;
} */

/* ---------------------------------------- FIM - TESTEMUNHOS ---------------------------------------- */


/* ---------------------------------------- TERMS ---------------------------------------- */

.pag-terms {
    padding: 60px 10px;
    max-width: 1600px;
    margin: auto;
}

.pag-terms h2 {
    margin-bottom: 40px;
}

.pag-terms a{
    color: #ABC132;
}


/* ---------------------------------------- FIM - TERMS ---------------------------------------- */







/* ---------------------------------------- SOBRE ---------------------------------------- */


.sobre-nos-descricao button {
    width: 206px;
    border-radius: 5px;
    height: 46px;
    background-color: #abc132;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: 0px;
}


.coluna-sobre-nos-child.sobre-nos-descricao{
    gap:0px;
    padding-bottom: 40px;
}



/* ---------------------------------------- FIM - SOBRE ---------------------------------------- */