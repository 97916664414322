.slider img{
    width: 100%;
    height: 500px;
    object-fit: cover;
    cursor: grab;
}

.slider .swiper-button-next:after, .slider  .swiper-button-prev:after {
    color: #fff !important;
    font-size: 30px;
}

.swiper-pagination-bullet-active{
    background: #abc132 !important;
}

